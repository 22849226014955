import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ListboxComponent from './ListboxComponent/ListboxComponent';


const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: option => (option.iata_code + option.name),
});

const filterLnmOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: option => (option.icao + option.iata + option.name),
});

const filterTailOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: option => (option.registration),
});

export default function VirtualizedAutocomplete(props) {
  const classes = useStyles();
  return (
    // <Autocomplete
    //   value={props.value}
    //   fullWidth
    //   disableClearable
    //   disableListWrap
    //   onChange={(event, newValue) => props.onChange(newValue)}
    //   classes={classes}
    //   ListboxComponent={ListboxComponent}
    //   options={props.options}
    //   getOptionLabel={(option) => option?.iata_code ?? ""}
    //   filterOptions={filterOptions}
    //   renderInput={(params) => <TextField {...params} label={props.label} InputLabelProps={{ shrink: true }} />}
    //   renderOption={(option) => <Typography noWrap>{option.iata_code} ({option.name})</Typography>}
    // />
    <Autocomplete
      value={props.value}
      fullWidth
      disableClearable
      disableListWrap
      onChange={(event, newValue) => props.onChange(newValue)}
      classes={classes}
      ListboxComponent={ListboxComponent}
      options={props.options}
      getOptionLabel={(option) => option?.icao ?? ""}
      filterOptions={filterLnmOptions}
      renderInput={(params) => <TextField {...params} label={props.label} InputLabelProps={{ shrink: true }} />}
      renderOption={(option) => <Typography noWrap>{`${option.icao} (${option.iata} - ${option.name})`}</Typography>}
    />
  );
}

export function VirtualizedTailNumberAutocomplete(props) {
  const classes = useStyles();
  return (
    <Autocomplete
      value={props.value}
      fullWidth
      disableClearable
      disableListWrap
      onChange={(event, newValue) => props.onChange(newValue)}
      classes={classes}
      ListboxComponent={ListboxComponent}
      options={props.options}
      getOptionLabel={(option) => option.departure && option.arrival ? `${option?.departure} - ${option?.arrival}` : ""}
      filterOptions={filterTailOptions}
      renderInput={(params) => <TextField {...params} label={props.label} InputLabelProps={{ shrink: true }} />}
      renderOption={(option) => <Typography noWrap>{`${option.departure} - ${option.arrival}`}</Typography>}
    />
  );
}