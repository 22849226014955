// @flow
import * as React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles, 
    Container, 
    CssBaseline, 
    Typography, 
    TextField, 
    Button, 
    Grid, 
    Box, 
    FormLabel  } from '@material-ui/core';
import { Link } from 'react-router-dom';
import IATALogo from '../../assets/logo/FLITEX-LOGO-1.png';
import axios from '../../axios-usercharges';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      height: '100px'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://flitex.net/">
          FliteX
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
function Login(props) {
const classes = useStyles();
const history = useHistory();
  const [controls, setControls] = React.useState({
      username: '',
      password: ''
  });

  const [error, setError] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (controls.username && controls.password) {
        axios.post('/api/user/login', controls)
            .then((response) => {
                setError('');
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('account', controls.username)
                history.push('/');
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data && err.response.data.errors) {
                    setError(err.response.data.errors[0].msg)
                } else{
                    setError('Invalid Username or Password')
                }
            })
    }
    
  }
  const inputChangedHandler = (event, controlName) => {
    setControls({
        ...controls,
        [event.target.name]: event.target.value
    });
  }

  return (
    <div className={classes.root}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={IATALogo} alt="IATA" className={classes.avatar} />
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={inputChangedHandler}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={inputChangedHandler}
          />
          {error && <FormLabel error>{error}</FormLabel>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>

            </Grid>
            <Grid item>
            
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </div>
  );
};

export default Login;