import React from "react";
import axios, { flightRouteInstance } from '../axios-usercharges';

const useFetch = (intialValue, options) => {
  const [response, setResponse] = React.useState(intialValue);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchData = async (options) => {
    try {
      setIsLoading(true);
      const result = await axios(options);
      setResponse(result.data);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchData(options);
  }, []);
  return [ response, error, isLoading, fetchData ];
};

export const useLnmFetch = (intialValue, options) => {
  const [response, setResponse] = React.useState(intialValue);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchData = async (options) => {
    try {
      setIsLoading(true);
      const result = await flightRouteInstance(options);
      setResponse(result.data);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchData(options);
  }, []);
  return [ response, error, isLoading, fetchData ];
};

export default useFetch;
