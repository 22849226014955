// @flow 
import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
//import IATALogo from '../../assets/logo/top_logo.png';
import FliteXLogo from '../../assets/logo/flitex-logo.png';
import AfraaLogo from '../../assets/logo/AFRAA_logo.png';
import { Link } from 'react-router-dom'
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Typography, Grid, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';


const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 300,
    '& .MuiButton-root': {
      fontSize: '12px',
    },
  },
  active: {
    width: "120px",
    color: "white",
    backgroundColor: "#95C6CE",
    fontWeight: 700,
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "0.9rem",
    padding: "20px",
    textAlign: "center",
    fontFamily: 'Mukta, sans-serif'
  },
  style: {
    width: "120px",
    color: "white",
    textDecoration: "none",
    fontSize: "0.9rem",
    padding: "20px",
    textAlign: "center",
    fontFamily: 'Mukta, sans-serif'
  },
  iconRoot: {
    display: "flex",
    width: '145px',
    height: '65px',
    position: 'relative',
    background: '#fff',
    top: '0px',
    justifyContent: "center",
  },
  heading: {
    fontFamily: 'Mukta, sans-serif',
    fontWeight: 600,
    fontSize: '2.3rem'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    height: '64px',
    backgroundColor: '#243A69',
    boxSizing: "border-box"
  },
  imgStyle: {
    marginTop: "25px"
  },
  loginInfo: {
    marginRight: 0,
    marginLeft: 'auto'
  },
}));

const imgStyle = {
  marginTop: "15px",

}

export default function Navbar(props) {
  const classes = useStyles();
  const logo = localStorage.account === "afraa" ? AfraaLogo : FliteXLogo;

  const [userRes, userError, userIsLoading, fetchUser] = useFetch({}, {  
    method: 'get',
    url: '/api/user'
  });

  const handleLogoutClick = () => {
    localStorage.removeItem('token');
    history.push('/');
  }

  const history = useHistory();


  return (
    <div className={classes.root} style={{ display: 'flex', justifyContent: 'space-between' }} >
      <AppBar position="static" elevation={0} >
        <Toolbar className={classes.toolbar}>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }} >
          <Icon classes={{ root: classes.iconRoot }}>
            <img alt="IATA" src={logo} width="80%" height="100%"/>
          </Icon>
          <Link to="/" className={props.index == 0 ? classes.active : classes.style}>CALCULATIONS</Link>
          <Link to="/Insights" className={props.index == 1 ? classes.active : classes.style}>INSIGHTS</Link>
          </div>
          <div style={{ flex: 1 }}>
          <Typography variant="h4" component="h1" className={classes.heading} >
            fliteINTEL
          </Typography>
          </div>
          <div className="login_details" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <IconButton size="small" ><CloudDownloadIcon style={{color: 'white'}} /></IconButton>
            </Grid>
            <Grid item>
            |
            </Grid>
            <Grid item>
              <IconButton size="small"><AccountCircleIcon style={{color: 'white'}} /></IconButton>
            </Grid>
            <Grid item style={{ fontSize: '0.9rem', color: 'white', fontFamily: 'Mukta, sans-serif'}} >{userRes.username}</Grid>
            <Grid item>
              <Button color="primary" onClick={handleLogoutClick} style={{ fontSize: '0.9rem', color: 'white', fontFamily: 'Mukta, sans-serif'}} >Log out</Button>
              </Grid>
          </Grid>
        </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};
