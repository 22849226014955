// @flow 
import * as React from 'react';
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Typography, Grid, IconButton, Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
  },
  headingContainer: {
    position: "relative",
  },
  loginDetails: {
    position: "absolute",
    right: '10px',
    top: '5px',
  },
}));

export function TopBar(props) {
    const classes = useStyles();
    const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [userRes, userError, userIsLoading, fetchUser] = useFetch({}, {  
      method: 'get',
      url: '/api/user'
    });
         
    const handleLogoutClick = () => {
      localStorage.removeItem('token');
      history.push('/');
    }
    return (
      <div classes={classes.headingContainer}>
        <Typography variant="h4" component="h1" className={classes.heading}>
          fliteGAINS
        </Typography>
        <div className={classes.loginDetails}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <IconButton size="small"><CloudDownloadIcon /></IconButton>
            </Grid>
            <Grid item>
            |
            </Grid>
            <Grid item>
              <IconButton size="small"><AccountCircleIcon /></IconButton>
            </Grid>
            <Grid item>{userRes.username}</Grid>
            <Grid item>
              <Button color="primary" onClick={handleLogoutClick}>Log out</Button>
              </Grid>
          </Grid>
        </div>
      </div>
    );
};