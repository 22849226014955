import * as React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Box, TablePagination, TableFooter } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import StyledTableRow from '../../../../components/StyledTableRow/StyledTableRow';
import StyledTableCell from '../../../../components/StyledTableCell/StyledTableCell';
import TablePaginationActions from '../../../../components/TablePaginationActions/TablePaginationActions';
import StyledBlackTableHeader from '../../../../components/StyledBlackTableHeader/StyledBlackTableHeader';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    background: theme.palette.common.white
  },
  tableHeader: {
    background: theme.palette.info.dark,
    "& th": {
      color: theme.palette.common.white,
      border: '1px solid #fff'
    },
  },
  highlightTableHeader: {
    fontSize: '20px'
  },
  row: {
      background: theme.palette.common.white,
      height: '30px'
  },
  currencyCell: {
    background: '#1D9EFF',
    color: '#fff'
  },
  amountCell: {
    background: '#0070C0',
    color: '#fff'
  }
}));

const PsuedoInvoice = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const country = props.countryCharges.country.country;
  const date = 'Jan-2021';
  const numFlights = props.countryCharges.country.total_flights;
  const currency = props.countryCharges.country.country_currency_code;
  const charge =  props.countryCharges.country.total_domestic_charge;
  const totalDistance = props.countryCharges.country.total_fir_dist;
  const totalTime = props.countryCharges.country.total_fir_time; 

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.countryCharges.userCharges.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderRow = (row) => {
    const est_tow = !isNaN(parseInt(row.flight.est_tow)) ? parseInt(row.flight.est_tow): row.flight.est_tow;
    return (
      <StyledTableRow key={row.file_id} className={classes.row}>
        <StyledTableCell align="center">{row.flight.flt_nbr}</StyledTableCell>
        {/* <StyledTableCell align="center">{row.file_id}</StyledTableCell> */}
        <StyledTableCell align="center">{row.flight.dep_apt_iata}</StyledTableCell>
        <StyledTableCell align="center">{row.flight.arr_apt_iata}</StyledTableCell>
        <StyledTableCell align="center">{row.flight.acft_type}</StyledTableCell>
        <StyledTableCell align="center">{row.flight.date_of_flt}</StyledTableCell>
        <StyledTableCell align="center"><NumberFormat value={est_tow} displayType={'text'} thousandSeparator={true} /></StyledTableCell>
        <StyledTableCell align="center">{row.seg_dist}</StyledTableCell>
        <StyledTableCell align="center">{row.seg_time}</StyledTableCell>
        <StyledTableCell align="right"><NumberFormat value={row.cost_local} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></StyledTableCell>
      </StyledTableRow>
    );
  }


  
  return (
    <TableContainer component={Box} elevation={1} className={classes.tableContainer}>
      <Table  size="small" aria-label="a dense table" ref={ref} >
        <StyledBlackTableHeader>
          <TableRow>
            <TableCell align="center" rowSpan={2} colSpan={1} className={classes.highlightTableHeader}>{country}</TableCell>
            <TableCell align="center" rowSpan={2} className={classes.highlightTableHeader}>{date}</TableCell>
            <TableCell align="center" className={classes.highlightTableHeader}><NumberFormat value={numFlights} displayType={'text'} thousandSeparator={true} /></TableCell>
            <TableCell align="center" className={classes.highlightTableHeader}>{currency}</TableCell>
            <TableCell align="center" className={classes.highlightTableHeader}><NumberFormat value={charge} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} /></TableCell>
            <TableCell align="center" rowSpan={2}>Total Distance</TableCell>
            <TableCell align="center" className={classes.highlightTableHeader}><NumberFormat value={parseInt(totalDistance)} displayType={'text'} thousandSeparator={true} /></TableCell>
            <TableCell align="center" rowSpan={2}>Total Time</TableCell>
            <TableCell align="center" className={classes.highlightTableHeader}>{totalTime}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Flights</TableCell>
            <TableCell align="center">Currency</TableCell>
            <TableCell align="center">Charge</TableCell>
            <TableCell align="center">N.M.</TableCell>
            <TableCell align="center">HH:MM:SS</TableCell>
          </TableRow>
        </StyledBlackTableHeader>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell align="center">Flight Number</TableCell>
            {/* <TableCell align="center">Flight Number</TableCell> */}
            <TableCell align="center">ORIGIN</TableCell>
            <TableCell align="center">DESTINATION</TableCell>
            <TableCell align="center">AIRCRAFT TYPE</TableCell>
            <TableCell align="center">DATE OF FLIGHT</TableCell>
            <TableCell align="center">Actual TOW (kg)</TableCell>
            <TableCell align="center">SEG DIST (km)</TableCell>
            <TableCell align="center">SEG TIME (HH:MM:SS)</TableCell>
            <TableCell align="center">CHARGE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { (rowsPerPage > 0
            ? props.countryCharges.userCharges.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : props.countryCharges.userCharges
            ).map((row, index) => renderRow(row))
          }
          {emptyRows > 0 && <TableRow>
            <TableCell colSpan={8}></TableCell>
            <TableCell align="center" className={classes.currencyCell}>{currency}</TableCell>
            <TableCell align="right" className={classes.amountCell}><NumberFormat value={charge} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} /></TableCell>
          </TableRow>}
          {emptyRows-1 > 0 && (
            <TableRow style={{ height: 30 * emptyRows-1 }}>
              <TableCell colSpan={10} />
            </TableRow>
          )}

        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              colSpan={10}
              count={props.countryCharges.userCharges.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
});


export default PsuedoInvoice;