import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import "./App.css";

import Calculation from "./containers/Calculation/Calculation";
import Login from "./containers/Login/Login";
import Insight from "./components/Insights/Insight";

import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { setupInterceptors } from './axios-usercharges';
import 'mapbox-gl/dist/mapbox-gl.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#00305b",
    },
    secondary: {
      main: "#E5176E",
    },
    warning: {
      main: "#f8b308",
    },
    error: {
      main: "#f44336",
    },
    success: {
      main: "#4caf50",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "Arial, Helvetica, sans-serif",
    button: {
      fontSize: 15,
      textTransform: "none",
      fontFamily: "Arial, Helvetica, sans-serif",
      border: "none",
      margin: "4px",
    },
    body1: {
      lineHeight: 1,
      fontSize: 13,
    },
  },
  overrides: {
    MuiTableCell: {
      sizeSmall: {
        padding: "3px",
      },
    },
    MuiButton: {
      root: {
        padding: "4px",
        borderRadius: "0px",
      },
      contained: {
        boxShadow: "none",
      },
    },
  },
});


function App() {
  const history = useHistory();
  setupInterceptors(history);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <ProtectedRoute exact path="/" component={Calculation} />
          <ProtectedRoute exact path="/Insights" component={Insight} />
          <Redirect to="/" />
        </Switch>{/*<Route exact path="/" component={Calculation} /> */}
      </Router>
    </ThemeProvider>
  );
}

export default App;
