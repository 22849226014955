// @flow
import * as React from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";
import LeftPanel from "./LeftPanel/LeftPanel";
import RightPanel from "./RightPanel/RightPanel";
import { TopBar } from "../../components/topbar/Topbar";
import Navbar from "../../components/navbar/Navbar";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 0px',
    flexGrow: 1
  },
  leftPanel: {
    borderRight: "1px solid #ececec",
  },
  gridContainer: {
    minHeight: '100vh',
    width: '100%'
  }
}));

export default function Calculation(props) {
  const classes = useStyles();

  return (
    <div>
      <Box
        className={classes.gridContainer}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="stretch"
      >
        {/* <TopBar /> */}
        <Navbar index={0} />
        <LeftPanel />
        {/* <Grid container className={classes.root}>
              <Grid item xs={12} md={2} className={classes.leftPanel}>
                <LeftPanel />
              </Grid>
              <Grid item xs={12} md={10}>
                <RightPanel />
              </Grid>
            </Grid> */}
      </Box>
    </div>
  );
}
