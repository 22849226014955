// @flow 
import * as React from 'react';
import { Typography, makeStyles, List, ListItem, ListItemText, ListItemIcon, Grid, Box } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import atcIcon from '../../../assets/icons/atc.png';
import RightPanel from '../RightPanel/RightPanel';
import ResulTable from '../RightPanel/RouteBasis/ResultTable/ResultTable';
import "../../../App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    color: grey[600],
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%'
  },
  header: {
    textAlign: "center",
    color: grey[600]
  },
  leftPanel: {
    width: '100%',
    height: '100%'
  },
  rightPanel: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  list: {
    width: '100%',
    fontSize: 'inherit',
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'anonymous',
    fontWeight: 'bold'
  },
  atcIcon: {
    width: '20px',
    opacity: '0.5',
    height: '24px'
  }
}));

export default function LeftPanel(props) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };
  return (
    <div >
      {/* <div style={{ gridRow: '1 /2', gridColumn: '1 / 2' }}>
                <div style={{ padding: '20px' }}>
                <List className={classes.list}>
                    <ListItem button
                        selected={selectedIndex === 0}
                        onClick={() => handleListItemClick(0)}>
                        <ListItemText primary={<Typography style={{ fontSize: '1.2rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }}  >ROUTE CALCULATION</Typography>} />
                        <ArrowForwardIosIcon />
                    </ListItem>
                    <ListItem button
                        selected={selectedIndex === 1}
                        onClick={() => handleListItemClick(1)}>
                    <ListItemText primary={<Typography style={{ fontSize: '1.2rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }}  >BETA INVOICE GENERATION</Typography>} />
                        <ArrowForwardIosIcon />
                    </ListItem>
                </List>
                </div>
            </div> */}
      <div>
        <RightPanel index={selectedIndex} />
      </div>
    </div>

    // <Grid container className={classes.root}>
    //     <Grid item xs={12} md={4} className={classes.leftPanel}>
    //     <div className={classes.root}>
    //         <List className={classes.list}>
    //             <ListItem button
    //                 selected={selectedIndex === 0}
    //                 onClick={() => handleListItemClick(0)}>
    //                 <ListItemText primary="ROUTE CALCULATION" />
    //                 <ArrowForwardIosIcon />
    //             </ListItem>
    //             <ListItem button
    //                 selected={selectedIndex === 1}
    //                 onClick={() => handleListItemClick(1)}>
    //                 <ListItemText primary="BETA INVOICE GENERATION" />
    //                 <ArrowForwardIosIcon />
    //             </ListItem>
    //         </List>
    //     </div>
    //     </Grid>
    //     <Grid item xs={12} md={8} className={classes.rightPanel}>
    //         <RightPanel index = {selectedIndex}/>
    //     </Grid>
    // </Grid>

  );
};